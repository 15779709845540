var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form-wizard',{staticClass:"mb-3 p-3",attrs:{"color":"#00A5AF","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":function($event){return handleSubmit(_vm.addBusiness)}}},[_c('tab-content',{attrs:{"before-change":function (){
        if(_vm.$refs.commercialDetails.$data.flags.invalid)
          { _vm.$refs.commercialDetails.handleSubmit(); }
        return !_vm.$refs.commercialDetails.$data.flags.invalid
      },"title":"Commercial Details"}},[_c('validation-observer',{ref:"commercialDetails"},[_c('commercial-details',{attrs:{"commercial-details":_vm.businessData,"donation-categories":_vm.activeDonationCategories,"classifications":_vm.activeClassifications,"sub-classifications":_vm.subClass,"languages":_vm.activeLanguages}})],1),_c('div',{staticClass:"btn-back"},[_c('back')],1)],1),_c('tab-content',{attrs:{"before-change":function (){
        if(_vm.$refs.commercialSocial.$data.flags.invalid)
          { _vm.$refs.commercialSocial.handleSubmit(); }
        return !_vm.$refs.commercialSocial.$data.flags.invalid
      },"title":"Social"}},[_c('validation-observer',{ref:"commercialSocial"},[_c('commercial-social',{attrs:{"commercial-details":_vm.businessData,"tag-options":_vm.activeTags.filter(function (tag){ return tag.category === 'business'; })}})],1)],1),_c('upload-progress',{attrs:{"progress":_vm.progress}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }