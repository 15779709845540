<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <!-- BODY -->
    <form-wizard
      color="#00A5AF"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3 p-3"
      @on-complete="handleSubmit(addBusiness)"
    >
      <tab-content
        :before-change="()=>{
          if($refs.commercialDetails.$data.flags.invalid)
            $refs.commercialDetails.handleSubmit();
          return !$refs.commercialDetails.$data.flags.invalid
        }"
        title="Commercial Details"
      >
        <validation-observer
          ref="commercialDetails"
        >
          <commercial-details
            :commercial-details="businessData"
            :donation-categories="activeDonationCategories"
            :classifications="activeClassifications"
            :sub-classifications="subClass"
            :languages="activeLanguages"
          />
        </validation-observer>
        <div class="btn-back">
          <back />
        </div>
      </tab-content>

      <tab-content
        :before-change="()=>{
          if($refs.commercialSocial.$data.flags.invalid)
            $refs.commercialSocial.handleSubmit();
          return !$refs.commercialSocial.$data.flags.invalid
        }"
        title="Social"
      >
        <validation-observer
          ref="commercialSocial"
        >
          <commercial-social
            :commercial-details="businessData"
            :tag-options="activeTags.filter(tag=>tag.category === 'business')"
          />
        </validation-observer>
      </tab-content>

      <upload-progress :progress="progress" />
    </form-wizard>
  </validation-observer>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { FormWizard, TabContent } from 'vue-form-wizard'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import commercialDetails from '@/common/components/Business/Add/CommercialDetails.vue'
import commercialSocial from '@/common/components/Business/Add/CommercialSocial.vue'
import UploadProgress from '@/common/components/common/FormInputs/UploadProgress.vue'

import getOrganizationDetails from '@/common/compositions/GeneralSettings/getGeneralSettings'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'
import Back from '@/common/components/common/FormInputs/Back.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    commercialDetails,
    commercialSocial,
    UploadProgress,
    Back,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      autocomplete: '',
      place: '',
      businessData: {
        name: '',
        lat: '',
        lng: '',
        logo: [],
        cover: [],
        about: '',
        phone_number: '',
        email: '',
        social_facebook: '',
        social_twitter: '',
        social_instagram: '',
        social_youtube: '',
        website: '',
        classifications: [2],
        subclassifications: [],
        address_type: '1',
        street: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
        language_id: '',
        tag_id: '',
        business_type: '',
        phoneNum: '',
        media_files: [],
      },
    }
  },
  computed: {
    subClass: {
      get() {
        return this.$store.getters['generalSettings/getActiveSubClassifications']
      },
    },
    classID: {
      get() {
        return this.businessData.classifications
      },
    },
  },
  watch: {
    classID(classID) {
      this.$store.dispatch('generalSettings/getClassSubClassificationRequest', classID)
    },
  },
  created() {
    this.resetSubClasses()
    this.$store.dispatch('generalSettings/getClassSubClassificationRequest', this.classID[0])
  },
  setup() {
    const { progress, calculateUploadPercentage } = uploadPercentage()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const {
      activeLanguages,
      activeTags,
      activeDonationCategories,
      activeClassifications,
    } = getOrganizationDetails()

    const {
      successfulOperationAlert,

    } = handleAlerts()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      activeLanguages,
      activeTags,
      activeDonationCategories,
      activeClassifications,
      successfulOperationAlert,
      progress,
      calculateUploadPercentage,
    }
  },
  methods: {
    resetSubClasses() {
      this.$store.commit('generalSettings/setSubClass', [])
    },
    addBusiness() {
      this.swapLatAndLong()
      this.$entities
        .post('internalops/commercial_entity',
          {
            ...this.businessData,
            parentID: this.businessData.parent?.id,
            logo: this.businessData.logo[0],
            cover: this.businessData.cover[0],
            facilitiesIDs: this.businessData.facilities?.map(item => item.id),
            servicesIDs: this.businessData.services?.map(item => item.id),
            productsIDs: this.businessData.products?.map(item => item.id),
            programsIDs: this.businessData.programs?.map(item => item.id),
          }, {
            onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
          }).then(() => {
          this.successfulOperationAlert('Business is added successfully')
          this.$router.push('/business/list')
        }).catch(err => {
          const duplicationError = err.response.data?.errors?.name[0]
          const [message, id] = duplicationError.split(',')
          if (message === 'Entity with same details already exist') {
            if (this.$can('update', 'global-EntitiesController')) {
              this.$router.push({ name: 'admin-edit-business', params: { id: parseInt(id, 10) } })
            } else if (this.$can('update', 'EntitiesController')) {
              this.$router.push({ name: 'edit-business', params: { id: parseInt(id, 10) } })
            }
          }
        })
    },
    swapLatAndLong() {
      [this.businessData.lat, this.businessData.lng] = [this.businessData.lng, this.businessData.lat] // this is because api reverse them
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
