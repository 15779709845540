<template>
  <div>
    <b-row>
      <b-col md="6">
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="Name"
          rules="required|min:3|max:200"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="commercialDetails.name"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Name"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="6">
        <!-- Contact Name -->
        <validation-provider
          #default="validationContext"
          name="Contact Name"
          rules="min:3|max:200"
        >
          <b-form-group
            label="Contact Person"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="commercialDetails.contact_name"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Contact Person"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Mobile Number -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          rules="min:6|max:14|phone"
          name="Mobile Number"
        >
          <b-form-group
            label="Mobile Number"
            label-for="mobile-number"
          >
            <b-form-input
              id="mobile-number"
              v-model="commercialDetails.phone_number"
              :state="getValidationState(validationContext)"
              type="tel"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Email -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Email"
          rules="email"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="commercialDetails.email"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- About -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="About"
          rules="max:255"
        >
          <b-form-group
            label="About"
            label-for="about"
          >
            <b-form-textarea
              id="about"
              v-model="commercialDetails.about"
              :state="getValidationState(validationContext)"
              placeholder="About"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-form-row class="my-2">
      <!-- Classification -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Classification"
          rules="required"
        >
          <b-form-group
            label="Classification"
            label-for="classification"
          >
            <v-select
              id="sub-classification"
              v-model="commercialDetails.classifications"
              :reduce="subclass => subclass.id"
              label="name"
              :options="classifications"
              :close-on-select="false"
              multiple
              disabled
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- subClassification -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Sub Classification"
          rules="required"
        >
          <b-form-group
            label="Sub Classification"
            label-for="sub-classification"
          >
            <v-select
              id="sub-classification"
              v-model="commercialDetails.subclassifications"
              multiple
              :reduce="subclass => subclass.id"
              label="name"
              :options="subClassifications"
              :close-on-select="false"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Language -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Language"
        >
          <b-form-group
            label="Language"
            label-for="language"
          >
            <v-select
              id="language"
              v-model="commercialDetails.language_id"
              multiple
              :reduce="(lang) => lang.id"
              label="language_name"
              :options="languages"
              :close-on-select="false"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col
            md="6"
            lg="8"
          >
            <facilities-select
              ref="facilities-select"
              :facilities.sync="commercialDetails.facilities"
              :category="initialFacility[entityType].category"
            />
          </b-col>
          <b-col class="mt-2">
            <b-link
              v-b-modal.facility-add-modal
              variant="primary"
            >
              <span class="text-nowrap">Add Facility</span>
            </b-link>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col
            md="6"
            lg="8"
          >
            <services-select
              ref="services-select"
              :services.sync="commercialDetails.services"
              :category="initialService[entityType].category"
            />
          </b-col>
          <b-col class="mt-2">
            <b-link
              v-b-modal.service-add-modal
              variant="primary"
            >
              <span class="text-nowrap">Add Service</span>
            </b-link>
          </b-col>
        </b-row>
      </b-col>

    </b-form-row>

    <b-form-row>
      <b-col>
        <products-select
          :products.sync="commercialDetails.products"
          category="business"
        />
      </b-col>

      <b-col md="6">
        <entity-select
          label="Entity Parent"
          :entity.sync="commercialDetails.parent"
          :filter="{classification: 'Business', withoutParent: true}"
        />

        <b-checkbox
          v-model="commercialDetails.is_main_branch"
          class="my-1"
        >
          Main Branch
        </b-checkbox>
      </b-col>
    </b-form-row>

    <!-- Modals -->
    <div>
      <!-- Add Facility -->
      <b-modal
        id="facility-add-modal"
        centered
        title="Add Facility"
        hide-footer
        no-close-on-backdrop
        size="lg"
        @hidden="resetFacility(entityType)"
      >
        <facility-form
          :facility="facility"
          :submit="addFacility"
          :is-category-enabled="false"
          :is-status-enabled="false"
        />
      </b-modal>

      <!-- Add Service -->
      <b-modal
        id="service-add-modal"
        centered
        title="Add Service"
        hide-footer
        no-close-on-backdrop
        size="lg"
        @hidden="resetService(entityType)"
      >
        <service-form
          :service="service"
          :submit="addService"
          :is-category-enabled="false"
          :is-status-enabled="false"
        />
      </b-modal>
    </div>

    <location-inputs
      :details="commercialDetails"
    />

    <b-form-row>

      <b-col md="6">
        <b-form-row>
          <b-col cols="12">
            <upload-image
              id="business-logo"
              label="Logo"
              rules="max-dimension:1200,1200|image-aspect-ratio:1,1"
              :images.sync="commercialDetails.logo"
            />
          </b-col>

          <b-col cols="12">
            <images-base-64-preview
              file-id="business-logo"
              :images.sync="commercialDetails.logo"
            />
          </b-col>
        </b-form-row>
      </b-col>

      <b-col md="6">
        <b-form-row>
          <b-col cols="12">
            <upload-image
              id="business-cover"
              label="Cover"
              rules="image-aspect-ratio:2,1"
              :images.sync="commercialDetails.cover"
            />
          </b-col>

          <b-col cols="12">
            <images-base-64-preview
              file-id="business-cover"
              :images.sync="commercialDetails.cover"
            />
          </b-col>
        </b-form-row>
      </b-col>
    </b-form-row>

    <b-form-row
      class="mt-1 border rounded p-2"
    >
      <b-col cols="12">
        <upload-image
          id="business-images"
          label="Images"
          :images.sync="commercialDetails.media_files"
          multiple
        />
      </b-col>

      <b-col cols="12">
        <images-base-64-preview
          file-id="business-images"
          :images.sync="commercialDetails.media_files"
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import LocationInputs from '@/common/components/common/FormInputs/LocationInputs.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import domainItemApi from '@/common/compositions/DomainItems/domainItemApi'
import UploadImage from '../../common/FormInputs/UploadImage.vue'
import ImagesBase64Preview from '../../common/FormInputs/ImagesBase64Preview.vue'
import FacilitiesSelect from '../../GeneralSettings/facilities/FacilitiesSelect.vue'
import ServicesSelect from '../../GeneralSettings/services/ServicesSelect.vue'
import FacilityForm from '../../GeneralSettings/facilities/FacilityForm.vue'
import ServiceForm from '../../GeneralSettings/services/ServiceForm.vue'
import ProductsSelect from '../../GeneralSettings/products/ProductsSelect.vue'
import EntitySelect from '../../Entities/EntitySelect.vue'

export default {
  components: {
    vSelect,
    LocationInputs,
    UploadImage,
    ImagesBase64Preview,
    FacilitiesSelect,
    ServicesSelect,
    FacilityForm,
    ServiceForm,
    ProductsSelect,
    EntitySelect,
  },
  props: {
    commercialDetails: {
      type: Object,
      default: () => {},
    },
    donationCategories: {
      type: Array,
      default: () => [],
    },
    classifications: {
      type: Array,
      default: () => [],
    },
    subClassifications: {
      type: Array,
      default: () => [],
    },
    languages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    entityType() {
      return 'business'
    },
  },
  setup() {
    const { confirmOperation } = handleAlerts()
    const { getValidationState, resetForm } = formValidation()

    const {
      initialItem: initialFacility, item: facility, addItemRequest: addFacilityRequest, resetItem: resetFacility,
    } = domainItemApi('entities', '/internalops/facility')

    const {
      initialItem: initialService, item: service, addItemRequest: addServiceRequest, resetItem: resetService,
    } = domainItemApi('entities', '/internalops/service')

    return {
      facility,
      addFacilityRequest,
      addServiceRequest,
      service,
      getValidationState,
      resetForm,
      confirmOperation,
      initialFacility,
      initialService,
      resetService,
      resetFacility,
    }
  },
  methods: {
    addService() {
      return this.addServiceRequest(this.entityType).then(() => {
        this.$bvModal.hide('service-add-modal')
        this.$refs['services-select'].getServices()
      })
    },
    addFacility() {
      return this.addFacilityRequest(this.entityType).then(() => {
        this.$bvModal.hide('facility-add-modal')
        this.$refs['facilities-select'].getFacilities()
      })
    },
    isImageBase64(image) {
      return image?.includes('data:image')
    },
    removeImageByIndex(index) {
      this.confirmOperation().then(() => {
        this.commercialDetails.media_files.splice(index, 1)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.business-image {
  position: relative;
  text-align: center;
  cursor: pointer;
}
.business-image::after {
  content: "X";
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: black;
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;
  line-height: 1.5em;
  margin: 5px 5px 0 0;
}
</style>
